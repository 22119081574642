<template>
    <div class="col-md-12" style="padding-top: 60px;padding: 0px;">
        <div class="scanCamera">
            <div id="bagScanCamera" ref="bagScanCamera">
                <img style="float:left;" src="@/assets/img/ok-bag.svg"/>
                <div class="explainScan" ref="explainScan">
                    <p ref="explainScanValue">{{explainScanValue}} </p>
                </div>
            </div>

            <div id="bagCartCamera" ref="bagCartCamera">
                <img style="float:right;" src="@/assets/img/bag-pointing.svg"/>
                <div class="explainCart" ref="explainCart">
                    <p ref="explainCartValue">{{explainCartValue}} </p>
                </div>
            </div>
            <div class="products" ref="products">
                <div v-for="product in products" :key="product.id">
                    <ProductBloc :scannedProduct="product"></ProductBloc>
                </div>
            </div>

            <qrcode-reader id="scanner" ref="scanner" class="cameraCustom" @decode="onDecode" v-if="scanType=='QRCODE'"></qrcode-reader>
            <video id="video" ref="barcode" class="cameraCustom" :hidden="!(scanType=='BARCODE')"></video>

            <Alert v-if="alertContent!=''" :alertContent="alertContent" :alertVariant="'danger'"></Alert>

            <div id="barcode" ref="barcode" class="cameraCustom" hidden></div>
        </div>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import ProductBloc from "@/components/Common/ProductBloc";
    import Alert from "@/components/Common/Alert";
    var Search = require("@/assets/js/SearchProduct");
    var Barcode = require("@/assets/js/Barcode");
    var Basket = require("@/assets/js/Basket");
    var MQTT = require("@/assets/js/MqttService");
    var App = require("@/assets/js/App");
    var Stats = require("@/assets/js/Stats");

    export default {
        name: "Scan",
        components: {ProductBloc, Alert},
        data() {
            return {
                ean: '',
                scanned_products: [],
                products: [],
                timers: {
                    productsTimer: null,
                    scannedProductsTimer: null,
                    errorTimer: null
                },
                scanType: "QRCODE",
                inPayment: this.$route.query.inPayment,
                needParameters: this.$route.query.needParameters,
                scanLoyaltyCard: this.$route.query.scanLoyaltyCard,
                alertContent: "",
                explainScanValue: this.$t('messageScanExplain'),
                explainCartValue: this.$t('messageCartExplain')
            }
        },
        mounted() {
            var showQRScanner = false;
            if(isNotNull(this.inPayment)){
                this.explainScanValue = this.$t('messageScanExplainPayment');
                showQRScanner = true;
            }else if(isNotNull(this.needParameters)){
                this.explainScanValue = this.$t('messageScanExplainParameters');
                showQRScanner = true;
            }

            var needQRCodeScanner = false;
            var needBarCodeScanner = false;
            if(isNotNull(this.scanLoyaltyCard) && this.scanLoyaltyCard == "true"){
                needBarCodeScanner = true;
            }
            if((isNotNull(this.inPayment) && this.inPayment == "true") || (isNotNull(this.needParameters) && this.needParameters == "true")){
                needQRCodeScanner = true;
            }

            var scanType = localStorage.getItem("DKC_scanType");
            if ((needBarCodeScanner && !needQRCodeScanner) || (!showQRScanner && isNotNull(scanType) && scanType == "BARCODE")) {
                this.scanType = "BARCODE";
                Barcode.start(this.onDecode);
                this.$refs.barcode.style.height = window.innerHeight + "px";
                this.$refs.barcode.style.width = window.innerWidth + "px";
            }else{
                this.scanType = "QRCODE";
                this.$refs.scanner.$el.style.height = window.innerHeight + "px";
                document.getElementsByClassName("qrcode-reader__camera")[0].style.height = window.innerHeight + "px";
                document.getElementsByClassName("qrcode-reader__inner-wrapper")[0].style.height = window.innerHeight + "px";
            }

            if(isNotNull(this.scanCabin)){
                var self = this;
                this.$parent.showLoader = true;
                setTimeout(function(){
                    self.$parent.showLoader = false;
                    self.$refs.bagCartCamera.style.display = "none";
                    self.$refs.bagScanCamera.style.display = "block";
                    self.explainScanValue = self.$t('messageCartExplainCabinScan');
                }, 1000)
            }

            if(isNotNull(this.scanLoyaltyCard)){
                this.$refs.bagCartCamera.style.display = "none";
                this.$refs.bagScanCamera.style.display = "block";
                this.$refs.explainScanValue.innerText = this.$t('messageCartExplainLoyaltyScan');
            }
        },
        methods: {
            onDecode(decodedString) {
                this.$refs.beep.play();
                if(isNotNull(this.inPayment)){
                    this.onPaymentScanned(decodedString);
                }else if(isNotNull(this.needParameters)){
                    this.onParametersScanned(decodedString);
                }else if(isNotNull(this.scanLoyaltyCard)){
                    this.onLoyaltyCardScanned(decodedString);
                }else{
                    this.onProductScanned(decodedString);
                }
            },
            onPaymentScanned(decodedString){
                var valinaKey = "payment_DKS:";
                var valinaKeyPaperSupport = "payment_paper_support:";
                if(decodedString.includes(valinaKey)){
                    var terminalId = decodedString.substring(valinaKey.length);
                    if(this.$parent.basket.totalAmount > 0) {
                        this.$parent.showLoader = true;
                        var loyaltyToUse = localStorage.getItem("DKC_loyaltyToUse");
                        MQTT.publishMessage("SCAN_VALINA", '{"basketPrice": ' + (this.$parent.basket.totalAmount - loyaltyToUse) + ', "terminalId": ' + terminalId + '}');
                        MQTT.susbscribeValinaSuccess(this);
                    }
                }else if(valinaKeyPaperSupport){
                    this.$parent.showLoader = true;
                    setTimeout(()=>{
                        this.$parent.showLoader = false;
                        this.$router.push(localStorage.getItem("DKC_base") + "/paymentdone");
                    }, 5000)
                }else{
                    this.alertContent = this.$t('scanErrorPayment');
                }
            },
            onParametersScanned(decodedString){
                var controlKey = "/p=";
                if(decodedString.includes(controlKey)){
                    var pathname = decodedString.substring(window.location.origin.length);
                    App.checkParameters(pathname, this);
                    this.$parent.setBase();
                    localStorage.setItem("DKC_needPublishPoster", "true");
                    window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + '/');
                }else{
                    this.alertContent = this.$t('scanErrorParameters');
                }
            },
            onLoyaltyCardScanned(decodedString){
                localStorage.setItem("DKC_loyaltyCard", decodedString.codeResult.code);
                this.$router.push(localStorage.getItem("DKC_base") + '/account');
            },
            onProductScanned(decodedString){
                this.$refs.products.classList.remove("shake");
                let self = this;
                let tmpList = [];
                if (decodedString.codeResult) {
                    this.ean = decodedString.codeResult.code;
                } else {
                    this.ean = decodedString.substring(decodedString.lastIndexOf('=') + 1);
                }
                //this.ean = "3228881011138";

                if (this.scanned_products.indexOf(this.ean) == -1) {
                    this.scanned_products.push(this.ean);
                    Search.getProductByEan(this.ean.split("_")[0], function(product) {
                        if (!product.error) {
                            clearTimeout(self.timers.productsTimer);
                            self.$refs.products.classList.add("shake");
                            tmpList.push(product);
                            product.ean = self.ean;
                            self.products = tmpList;

                            if(isNotNull(product.productCrossSell)){
                                localStorage.setItem("DKC_productCrossSell", JSON.stringify(product.productCrossSell));
                                self.$parent.productCrossSell = product.productCrossSell;
                            }
                            Stats.setStats({nbProductsScanned: 1});

                            self.timers.productsTimer = setTimeout(function() {
                                self.products = [];
                                self.$refs.products.classList.remove("shake");
                                self.$refs.bagScanCamera.style.display = "block";
                                self.$refs.bagCartCamera.style.display = "none";
                                self.$refs.explainCart.classList.remove("alreadyScanned");
                                self.explainCartValue = self.$t('messageCartExplain');
                            }, 5000);

                            if (self.ean.indexOf("_") > -1 && self.existInBasket(self.ean)) {
                                self.$refs.explainCart.classList.add("alreadyScanned");
                                self.$refs.bagCartCamera.style.display = "block";
                                self.$refs.bagScanCamera.style.display = "none";
                                self.explainCartValue = self.$t('productAlreadyInBakset');
                            } else {
                                Basket.addProductToLocalBasket(product);
                                if(isNotNull(self.$parent.productCrossSell) && product.ean.split("_")[0] == self.$parent.productCrossSell.ean){
                                    MQTT.publishMessage("SCAN_PRODUCT", '{"productSize": "' + product.descriptionFr + '", "productPrice": ' + product.price + ', "isCrossSell": "true"}');
                                    self.$parent.productCrossSell = null;
                                    localStorage.removeItem("DKC_productCrossSell");
                                    localStorage.removeItem("DKC_fromCrossSell");
                                }else{
                                    MQTT.publishMessage("SCAN_PRODUCT", '{"productSize": "' + product.descriptionFr + '", "productPrice": ' + product.price + ', "isCrossSell": "false"}');
                                }
                                self.$parent.basket = Basket.getBasket();
                                self.$parent.basketChange();
                                self.explainCartValue = self.$t('messageCartExplain');
                                self.$refs.explainCart.classList.remove("alreadyScanned");
                                self.$refs.bagCartCamera.style.display = "block";
                                self.$refs.bagScanCamera.style.display = "none";
                                self.explainScanValue = self.$t('messageScanExplain');
                            }
                        } else {
                            self.$refs.explainScan.classList.add("alreadyScanned");
                            self.explainScanValue = self.$t('productNotFound');
                            self.timers.errorTimer = setTimeout(function() {
                                self.$refs.explainScan.classList.remove("alreadyScanned");
                                self.explainScanValue = self.$t('messageScanExplain');
                            }, 5000);
                        }
                    });
                }
            },
            onSubmit(evt) {
                evt.preventDefault();
            },
            existInBasket(ean) {
                var exist = false;
                this.$parent.basket.lockedProducts.forEach(function(lockedProduct) {
                    if (lockedProduct == ean) {
                        exist = true;
                    }
                })
                return exist;
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            next();
        },
        watch: {
            scanned_products: function() {
                var self = this;
                clearTimeout(this.timers.scannedProductsTimer);
                this.timers.scannedProductsTimer = setTimeout(function() {
                    self.scanned_products.shift();
                }, 2000)
            }

        }
    }
</script>